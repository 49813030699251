import * as React from "react"
import {Link} from "gatsby"
import * as styles from "./header.module.css"

const Header = ({siteTitle}) => (
    <header className={styles.header}>
        <div className={styles.title}>
            <Link to="/">{siteTitle}</Link>
        </div>
    </header>
)

export default Header
