import React from "react"
import * as styles from "./rectangleAd.module.css"

const RectangleAd = () => {
    return (
        <section className={styles.wrapper}>
            <a href="https://hb.afl.rakuten.co.jp/hsc/21dae630.fd1de90a.21dae54c.fa271e9e/?link_type=pict&ut=eyJwYWdlIjoic2hvcCIsInR5cGUiOiJwaWN0IiwiY29sIjoxLCJjYXQiOiIxIiwiYmFuIjoiMTYyNDAxMyIsImFtcCI6ZmFsc2V9"
               target="_blank"
               rel="nofollow sponsored noopener">
                <img
                    width={200}
                    height={200}
                    src="https://hbb.afl.rakuten.co.jp/hsb/21dae630.fd1de90a.21dae54c.fa271e9e/?me_id=1278256&me_adv_id=1624013&t=pict"
                    alt="Rakuten kobo"/>
            </a>
        </section>
    )
}

export default RectangleAd
