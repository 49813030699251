import * as React from "react"
import * as styles from "./sidebar.module.css"
import CategoryList from "./categoryList";
import NewPostList from "./newPostList";
import RectangleAd from "./rectangleAd";

const SideBar = () => (
    <aside className={styles.sidenav}>
        <RectangleAd />
        <CategoryList/>
        <NewPostList/>
    </aside>
)

export default SideBar
