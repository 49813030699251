import * as React from "react"
import {Link} from "gatsby"
import * as styles from "./footer.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faHome, faChevronCircleUp} from "@fortawesome/free-solid-svg-icons"

const Footer = ({siteTitle}) => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerInner}>
                <div className={styles.buttons}>
                    <Link to="/"><FontAwesomeIcon icon={faHome}/></Link>
                    <a href="/"
                       aria-label="Up"
                       onClick={(event) => {
                           window.scrollTo({
                               top: 0,
                               behavior: "smooth",
                           });
                           event.preventDefault();
                       }}><FontAwesomeIcon icon={faChevronCircleUp}/></a>
                </div>
                <div className={styles.rights}>© {siteTitle} All rights reserved.</div>
            </div>
        </footer>
    )
}

export default Footer
