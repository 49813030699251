import React, {useMemo} from "react"
import {Link, graphql, useStaticQuery} from "gatsby"
import * as styles from "./categoryList.module.css"
import SectionLabel from "./sectionLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons"

const CategoryList = () => {
    const data = useStaticQuery(graphql`
        query {
            allMicrocmsPost {
                edges {
                    node {
                        category {
                            name
                            slug
                        }
                    }
                }
            }
        }
    `)

    const categories = useMemo(() => {
        const categoryMap = {}
        data.allMicrocmsPost.edges.forEach((edge) => {
            const count = categoryMap[edge.node.category.slug]?.count || 0
            categoryMap[edge.node.category.slug] = {
                ...edge.node.category,
                count: count + 1
            }
        })
        return Object.values(categoryMap)
            .sort((a, b) => {
                if (a.count === b.count) return 0
                return a.count < b.count ? 1 : -1
            })
    }, [data])

    return (
        <section className={styles.wrapper}>
            <SectionLabel>
                <span className={styles.labelIcon}><FontAwesomeIcon icon={faFolderOpen}/></span>カテゴリ一覧
            </SectionLabel>
            <ul className={styles.ul}>
                {categories.map(category => (
                    <li key={category.slug} className={styles.li}>
                        <Link to={`/${category.slug}`}>
                            {category.name}<span className={styles.postCount}>{category.count}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </section>
    )
}

export default CategoryList
