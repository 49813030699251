import * as React from "react"
import * as styles from "./sectionLabel.module.css"

const SectionLabel = ({children}) => (
    <div className={styles.label}>
        <h2 className={styles.title}>{children}</h2>
    </div>
)

export default SectionLabel
