import * as React from "react"
import PropTypes from "prop-types"
import {graphql, useStaticQuery} from "gatsby"

import Header from "./header"
import Footer from "./footer"
import SideBar from "./sidebar";
import * as styles from "./layout.module.css"

const Layout = ({children}) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <div className={styles.wrapper}>
            <Header siteTitle={data.site.siteMetadata?.title || `Title`}/>
            <div className={styles.content}>
                <main className={styles.main}>{children}</main>
                <SideBar/>
            </div>
            <Footer siteTitle={data.site.siteMetadata.title}/>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
