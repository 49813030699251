import * as React from "react"
import {Link, graphql, useStaticQuery} from "gatsby"
import SectionLabel from "./sectionLabel";
import * as styles from "./newPostList.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faNewspaper } from "@fortawesome/free-solid-svg-icons"

const NewPostList = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allMicrocmsPost(
                    limit: 3
                    sort: { fields: [publishedAt], order: DESC }
                ) {
                    edges {
                        node {
                            postId
                            title
                            eyecatch {
                                url
                            }
                            category {
                                slug
                            }
                        }
                    }
                }
            }
        `
    )
    return (
        <section className={styles.wrapper}>
            <SectionLabel><span className={styles.labelIcon}><FontAwesomeIcon icon={faNewspaper}/></span>新着記事</SectionLabel>
            <ul className={styles.list}>
                {data.allMicrocmsPost.edges.map(edge => {
                    const post = edge.node
                    return (
                        <li key={post.postId} className={styles.item}>
                            <Link to={`/${post.category.slug}/${post.postId}`}>
                                <article className={styles.article}>
                                    <img className={styles.eyeCatch}
                                         src={`${post.eyecatch.url}?auto=format&auto=compress&fm=webp&h=160&ar=1:1&fit=crop&fp-y=0.5`}
                                         alt={post.title}
                                    />
                                    <h2 className={styles.title}>{post.title}</h2>
                                </article>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </section>
    )
}

export default NewPostList
